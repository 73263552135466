// Things to be executed when the page loads

import initTrackEvents from './track_events'
import initFeedback from './feedback'
import initUppyPoll from './uppy_poll'

const onDocumentReady = () => {
  initTrackEvents()
  initFeedback()
  initUppyPoll()
}

// const onDocumentReadyRender = () => {
// }

document.addEventListener('turbo:load', onDocumentReady)
document.addEventListener('turbo:render', onDocumentReady)
