import { Controller } from "@hotwired/stimulus";

// The classnames here should match the classes within the DjHelper
const vote_group_selected_class = 'stage-vote-items-group--selected'
const vote_item_selected_class = 'stage-vote-item--selected'
const vote_item_taken_class = 'stage-vote-item--taken'

export default class extends Controller {
  static targets = ["primaryBtn"];

  connect = () => {
    this.groupPositions = {}
    this.positionGroups = {}
    this.allGroups = this.element.querySelectorAll('.stage-vote-items-group')

    this.initExistingPositionsAndGroups()

    // console.log('groupPositions', this.groupPositions)
  }

  initExistingPositionsAndGroups = () => {
    this.allGroups.forEach((group, groupIndex) => {
      const groupNum = groupIndex + 1

      group.querySelectorAll('.stage-vote-item').forEach((item, itemIndex) => {
        const position = itemIndex + 1
        if (item.classList.contains(vote_item_selected_class)) {
          this.groupPositions[groupNum] = position
          this.positionGroups[position] = groupNum
        }
      })
    })
  }

  select = (event) => {
    const currentRadio = event.target
    // const currentItem = currentRadio.closest('.stage-vote-item')
    const currentGroup = currentRadio.closest('.stage-vote-items-group')

    // 1) actualize selected positions
    this.allGroups.forEach((group, groupIndex) => {
      if (group === currentGroup) {
        const selectedPosition = parseInt(currentRadio.value)
        const groupNum = groupIndex + 1
        let oldPosition = this.groupPositions[groupNum];
        let oldGroup = this.positionGroups[selectedPosition];

        if (oldPosition) { delete this.positionGroups[oldPosition]; }
        if (oldGroup) { delete this.groupPositions[oldGroup]; }
        this.groupPositions[groupNum] = selectedPosition
        this.positionGroups[selectedPosition] = groupNum

        return
      }
    })

    this.autoSelectLastItem(this.allGroups.length)

    // 2) update items' classes
    const selectedPositions = Object.values(this.groupPositions)
    this.allGroups.forEach((group, groupIndex) => {
      const groupNum = groupIndex + 1
      const selectedPosition = this.groupPositions[groupNum]
      const altPositions = selectedPositions.filter(p => p !== selectedPosition)

      if (selectedPosition) {
        group.classList.add(vote_group_selected_class)
      } else {
        group.classList.remove(vote_group_selected_class)
      }

      group.querySelectorAll('.stage-vote-item').forEach((item, itemIndex) => {
        const radioBtn = item.querySelectorAll('input[type=radio]')[0]
        const position = itemIndex + 1

        if (position === selectedPosition) {
          item.classList.remove(vote_item_taken_class)
          item.classList.add(vote_item_selected_class)
          radioBtn.checked = true
        } else if (altPositions.includes(position)) {
          item.classList.remove(vote_item_selected_class)
          item.classList.add(vote_item_taken_class)
          radioBtn.checked = false
        } else {
          item.classList.remove(vote_item_selected_class)
          item.classList.remove(vote_item_taken_class)
          radioBtn.checked = false
        }
      })

    })

    // 3) actualize primary button
    if (Object.values(this.positionGroups).length === this.allGroups.length) {
      this.primaryBtnTarget.classList.add('stage-vote-btn--active')
      this.primaryBtnTarget.disabled = false
    } else {
      this.primaryBtnTarget.classList.remove('stage-vote-btn--active')
      this.primaryBtnTarget.disabled = true
    }
  }

  autoSelectLastItem = (groupsLength) => {
    const selectedPositions = Object.values(this.groupPositions)
    if (selectedPositions.length === groupsLength - 1) {
      const selectedGroups = Object.values(this.positionGroups)

      let missingPosition;
      let missingGroup;

      for (let i = 1; i <= groupsLength; i++) {
        if (!selectedPositions.includes(i)) { missingPosition = i; }
        if (!selectedGroups.includes(i)) { missingGroup = i; }
      }

      this.groupPositions[missingGroup] = missingPosition
      this.positionGroups[missingPosition] = missingGroup
    }
  }
}
