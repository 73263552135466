import { Controller } from '@hotwired/stimulus'

// ref: https://gist.github.com/thomasvanholder/1778df30141e4c47362102518930c043
function getTimeRemaining(endtime) {
  let total = endtime - Date.now();
  if (total < 0) { total = 0; }

  const seconds = Math.floor((total / 1000) % 60);
  const minutes = Math.floor((total / 1000 / 60) % 60);
  const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
  const days = Math.floor(total / (1000 * 60 * 60 * 24));

  return { total, days, hours, minutes, seconds };
}

export default class extends Controller {
  static targets = ["days", "hours", "minutes", "seconds"]
  static values = {
    finishesAt: Number,
  }

  connect = () => {
    this.finishesAt = new Date(this.finishesAtValue * 1000)

    if (isNaN(this.finishesAt)) {
      console.error("Stimulus(timer): Missing finishesAt value. Please set data-timer-finishes-at-value attribute.")
      console.error(`finishesAt: ${this.finishesAt} // finishesAtValue: ${this.finishesAtValue}`)
    } else {
      this.updateClock()
      this.timeinterval = setInterval(this.updateClock, 1000);
    }
  }

  updateClock = () => {
    const t = getTimeRemaining(this.finishesAt)

    if (this.hasDaysTarget) {
      this.daysTarget.innerHTML = t.days;
    }
    this.hoursTarget.innerHTML = ("0" + t.hours).slice(-2);
    this.minutesTarget.innerHTML = ("0" + t.minutes).slice(-2);
    this.secondsTarget.innerHTML = ("0" + t.seconds).slice(-2);

    if (t.total <= 0) {
      clearInterval(this.timeinterval);
    }
  }
}
