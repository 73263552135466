// import Rails from '@rails/ujs'

// consider replacing this logic with https://github.com/rails/request.js
export function getMetaElement(name: string): HTMLMetaElement | null {
  return document.querySelector(`meta[name="${name}"]`)
}

export function getMetaContent(name: string) {
  const element = getMetaElement(name)
  return element && element.content
}

export function getCsrfToken(): string {
  return getMetaContent("csrf-token") || ''
}

export function formData(params: object) {
  let data = new FormData()
  Object.entries(params).map(([key, value]) => {
    data.append(key, value as string)
  })

  return data
}

export function postFormData(url: string, data: object) {
  const headers = {
    // 'Content-Type': 'application/json',
    'Accept': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-Token': getCsrfToken(),
  }

  fetch(url, {
    method: 'POST',
    mode: 'same-origin',
    credentials: 'same-origin',
    headers: headers,
    body: formData(data),
  })
}

export function hexToRGB(h) {
  let r, g, b;

  // 3 digits
  if (h.length == 4) {
    r = "0x" + h[1] + h[1];
    g = "0x" + h[2] + h[2];
    b = "0x" + h[3] + h[3];

  // 6 digits
  } else if (h.length == 7) {
    r = "0x" + h[1] + h[2];
    g = "0x" + h[3] + h[4];
    b = "0x" + h[5] + h[6];
  }

  return "rgb("+ +r + "," + +g + "," + +b + ")";
}

export function contrastColor(hexcolor){
  var r = parseInt(hexcolor.substr(1,2),16);
  var g = parseInt(hexcolor.substr(3,2),16);
  var b = parseInt(hexcolor.substr(5,2),16);
  var yiq = ((r*299)+(g*587)+(b*114))/1000;
  return (yiq >= 128) ? 'black' : 'white';
}
