import { Controller } from '@hotwired/stimulus'
import debounceFn from 'debounce-fn'
import { post } from '@rails/request.js'

const deboundeWaitInterval = 50
const pixelsToTrigger = 1000

export default class extends Controller {
  static values = {
    url: String,
  }

  connect = () => {
    addEventListener('scroll', this.onScrollDebounced)
  }

  disconnect = () => {
    removeEventListener('scroll', this.onScrollDebounced)
  }

  onScroll = (_event) => {
    const triggerElement = this.element;
    const triggerElementOffset = triggerElement.offsetTop

    if (triggerElement && triggerElementOffset) {
      const currentOffset = window.scrollY + window.innerHeight

      if (currentOffset + pixelsToTrigger >= triggerElementOffset) {
        removeEventListener("scroll", this.onScrollDebounced)
        // console.log('scroll_auto_load_triggered', this.urlValue)
        post(this.urlValue)
      }
    }
  }

  onScrollDebounced = debounceFn(this.onScroll, {wait: deboundeWaitInterval})
}
