// v2022.10.10 - source SaaSHub
import { Controller } from '@hotwired/stimulus'

// ref: https://discuss.hotwired.dev/t/add-and-remove-eventlisteners/710/2
// const event = new CustomEvent(modalOpenEventName)

export default class extends Controller {
  static values = {
    modalName: String,
  }

  connect = () => {
    if (this.modalNameValue) {
      this.modalOpenEvent = new CustomEvent(`${this.modalNameValue}:open`)
    } else {
      this.modalOpenEvent = new CustomEvent('modal:open')
    }
  }

  open = () => window.dispatchEvent(this.modalOpenEvent)
}
