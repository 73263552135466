// NOTICE: all functions here should start with "alpine" prefix
import { post } from '@rails/request.js'

// To be attached as an "@input.once" event on input fields:
window.alpineAutoExpandPollOptions = function(root, el, refs) {
  const lastInput = Array.from(root.querySelectorAll('.poll-option-input')).pop()
  if (lastInput === el) {
    if (refs.add_option_btn) {
      refs.add_option_btn.click()
    }
  }
}

window.alpineNewPollFindSimilar = function(title) {
  post('/polls/new_poll_find_similar', {
    body: { title: title },
    contentType: "application/json",
    responseKind: "turbo-stream",
  })
}
