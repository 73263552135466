import { postFormData } from './utils'

// taken from internal_api (libhunt)
const apiPath = (path: string) => (`/api${path}`)

const clickSelector = '.trck-evnt'
const autoSelector = '.track-auto'

function getADataset(event: Event): DOMStringMap | null {
  let element = event.target as HTMLElement | null

  if (element === null) return null

  if (element.tagName === 'A') {
    return element.dataset
  } else {
    if (!element.parentNode) return null
    return (element.parentNode as HTMLElement).dataset
  }
}

function trackEvent(event: Event) {
  const data = getADataset(event)
  if (data === null) return
  postFormData(apiPath('/ahoy/event'), data)
}

function trackAuto(element: HTMLElement) {
  const data = element.dataset
  if (data === null) return
  postFormData(apiPath('/ahoy/event'), data)
}

export default function initTrackEvents(): void {
  document.querySelectorAll(clickSelector).forEach((element) => {
    element.addEventListener('click', trackEvent)
  })
  document.querySelectorAll(autoSelector).forEach((element) => {
    trackAuto(element as HTMLElement)
  })
}
