// v2022.10.10 - source SaaSHub
import { Controller } from '@hotwired/stimulus'

const openClassName = 'is-active'

// ref: https://discuss.hotwired.dev/t/add-and-remove-eventlisteners/710/2
export default class extends Controller {
  static values = {
    placeholder: String,
    name: String,
  }

  static targets = ["box"]

  connect = () => {
    if (this.nameValue) {
      this.modalOpenEventName = `${this.nameValue}:open`
    } else {
      this.modalOpenEventName = 'modal:open'
    }

    window.addEventListener(this.modalOpenEventName, this.open)
    document.addEventListener('keydown', this.close)
  }

  disconnect = () => {
    window.removeEventListener(this.modalOpenEventName, this.open)
    document.removeEventListener('keydown', this.close)
  }

  open = () => {
    this.element.classList.add(openClassName)
  }

  close = (event) => {
    if (event.keyCode == 27 || event.type == 'click') {
      this.element.classList.remove(openClassName)
      this.boxTarget.innerHTML = this.placeholderValue
    }
  }
}
