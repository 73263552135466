const poll_image_input_id = 'poll-image-input'

export default function initUppyPoll() {
  if (!window.Uppy) return null;

  window.uppyStatusBarQueryId = '#image-upload-status-bar';

  var uppy = new window.Uppy({
    id: 'uppy',
    debug: false,
    autoProceed: true,
    restrictions: {
      maxFileSize: 10485760,
      allowedFileTypes: ['image/*', '.jpg', '.jpeg', '.png', '.gif'],
    }
  })

  // uppy.use(window.UppyForm, {
  //   target: '#poll-form',
  //   // resultName: 'upload-result',
  //   getMetaFromForm: true,
  //   addResultToForm: true,
  //   submitOnSuccess: false,
  //   triggerUploadOnSubmit: false,
  // })

  uppy.use(window.UppyStatusBar, {
    id: 'StatusBar',
    target: window.uppyStatusBarQueryId,
    hideAfterFinish: true,
    showProgressDetails: false,
    hideUploadButton: false,
    hideRetryButton: false,
    hidePauseResumeButton: false,
    hideCancelButton: false,
    doneButtonHandler: null,
    locale: {},
  })

  // uppy.use(Uppy.Tus, { endpoint: 'https://tusd.tusdemo.net/files/' })

  // consider providing headers to XHRUpload
  // headers: {
  //   'X-CSRF-TOKEN': $('meta[name="token"]').attr('content')
  // }
  uppy.use(window.UppyXHRUpload, { endpoint: '/file_uploads/' })

  // uppy.on('complete', (result) => {
  //   // console.log('Upload complete! We’ve uploaded these files:', result.successful)
  //   // console.log(result)
  //   // const statuBar = document.querySelector(window.uppyStatusBarQueryId)
  // })

  uppy.on('upload-success', (_file, response) => {
    imageUrlEvent = new CustomEvent('poll-set-image-url', { detail: response.uploadURL })
    window.dispatchEvent(imageUrlEvent)
  })

  // uppy.use(Uppy.DragDrop, { target: '#drag-drop-area' })
  // uppy.use(window.UppyFileInput, { target: '.uppy-file-input', pretty: true, inputName: 'stan' })

  // The id here should match polls_helper#poll_image_input_id
  const pollImageInput = document.querySelector(`#${poll_image_input_id}`)
  pollImageInput.addEventListener('change', (event) => {
    const files = Array.from(event.target.files)

    files.forEach((file) => {
      try {
        uppy.addFile({
          source: 'file input',
          name: file.name,
          type: file.type,
          data: file,
        })
      } catch (err) {
        if (err.isRestriction) {
          // handle restrictions
          console.log('Restriction error:', err)
        } else {
          // handle other errors
          console.error(err)
        }
      }
    })
  })

  // uppy.on('file-added', (file) => {
  //   console.log('Added file', file)
  // })
  // uppy.log('initialized onDocumentReady...')
  // console.log('onDocumentReady')
}
