import Feedback from '@betahuhn/feedback-js'
import { postFormData } from './utils'

const feedbackEndpoint = '/feedback_messages'

window.addEventListener('feedback-submit', (event) => {
  postFormData(feedbackEndpoint, event.detail)
})

export default function initFeedback() {
  new Feedback({
    endpoint: feedbackEndpoint,
    id: window.feedbackId,
    primary: '#763EF3', // primary color
    emailField: true,
    events: true,
  }).renderButton()
}
