// Entry point for the build script in your package.json
// import "@hotwired/turbo-rails"
import "chartkick/chart.js"
// import { Chart } from 'chart.js';
// import ChartDataLabels from 'chartjs-plugin-datalabels';
// Chart.register(ChartDataLabels);

// Change default options for ALL charts
// import { contrastColor } from './utils'
// Chart.defaults.set('plugins.datalabels', {
//   color: function(context) {
//     let hex = context.dataset.backgroundColor[context.dataIndex];
//     return contrastColor(hex);
//   },
//   formatter: function(value, context) {
//     return context.chart.data.labels[context.dataIndex];
//   }
// });


import "./turbo_with_prefetch"
import "./controllers"

// import Rails from "@rails/ujs"
// Rails.start()

// Alpine-turbo-drive-adapter allows you to add support for Alpine.js to your Turbolinks/Turbo powered apps.
// It handles events to properly clean up the DOM from Alpine generated code when navigating between pages.
// import 'alpinejs'
import 'alpine-turbo-drive-adapter'
import Alpine from 'alpinejs'
window.Alpine = Alpine
Alpine.start()
import './alpine_global_utilities'

import 'lite-youtube-embed'

import "./on_document_ready"
